<script>
import micoapp_qrcode from '@/assets/images/otp_micoapp.jpg';
import QRCode from 'qrcode';
import { required } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from '@/state/helpers';
import i18n from '@/i18n';

export default {
  name: 'Login',
  props: ['data'],
  emits: ['getBackInfo'],
  data() {
    return {
      // 输入框绑定值
      num11: undefined,
      num22: undefined,
      num33: undefined,
      num44: undefined,
      num55: undefined,
      num66: undefined,

      // 其他状态
      username: '',
      email: '',
      password: '',
      submitted: false,
      extendvoid: false,
      qrcodelogin: false,
      inputList: ['', '', '', '', '', ''],
      authcodes: '',
      keyboard: false,
      ismobile: false,
      loading: false,
      remember: false,
      logintype: 'password',
      otpcode: '',
      qrcodelink: '',
      qrcodeloading: true,
      qrcodelogin_request: false,
      qrcodecountdown: '',
      qrcodeexpire_seconds: 0,
      otp_status: false,
      isAuthError: false,
      authError: '',
      counter: 0,
      imgsrc: micoapp_qrcode,
    };
  },
  computed: {
    ...mapState({
      notification: (state) => state.notification,
    }),
    pd() {
      return `${this.num11 || ''}${this.num22 || ''}${this.num33 || ''}${this.num44 || ''}${this.num55 || ''}${this.num66 || ''}`;
    },
  },
  watch: {
    pd(val) {
      this.getBackInfo(val);
    },
    data: {
      handler(val) {
        if (val) {
          let string = typeof val === 'number' ? val.toString() : val;
          let arr = string.split('');
          arr.forEach((item, index) => {
            const num = Number(item);
            this[`num${index + 1}${index + 1}`] = isNaN(num) ? undefined : num;
          });
        }
      },
      immediate: true,
    },
  },
  validations: {
    email: { required },
    password: { required },
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,

    // Emit the getBackInfo event
    getBackInfo(value) {
      this.$emit('getBackInfo', value);
    },

    // loseBlur method
    loseBlur(id) {
      const key = `num${id}${id}`;
      let value = this[key];

      if (value === undefined || value === null) {
        this[key] = undefined;
        return;
      }

      // 保留第一个数字字符
      const newValue = String(value).replace(/\D/g, '').charAt(0) || undefined;
      this[key] = newValue;

      if (newValue) {
        const nextId = Number(id) + 1;
        if (nextId <= 6) {
          const nextInput = this.$refs[`num${nextId}`];
          nextInput && nextInput.focus();
        } else {
          const lastInput = this.$refs.num6;
          lastInput && lastInput.blur();
        }
      }
    },

    // 清空所有数字
    clearAllNumber() {
      this.num11 = '';
      this.num22 = '';
      this.num33 = '';
      this.num44 = '';
      this.num55 = '';
      this.num66 = '';
      this.$refs.num1 && this.$refs.num1.focus();
    },


    // 处理输入
    onInput(key) {
      this.authcodes = (this.authcodes + key).slice(0, 6);
      if (this.authcodes.length === 6) {
        this.tryToLogInExtend();
      }
    },

    onDelete() {
      this.authcodes = this.authcodes.slice(0, this.authcodes.length - 1);
    },

    // 处理登录尝试
    tryToLogIn() {
      this.submitted = true;
      // 触发 Vuelidate 的验证
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.$axios
          .post(this.apiuri, {
            action: 'login',
            username: this.email,
            password: this.password,
            remember: this.remember,
          })
          .then((response) => {
            if (response.data.status === 403) {
              this.toast(this.$t('login.needauth'));

              this.extendvoid = true;
              this.keyboard = true;
              this.isAuthError = true;
              this.authError = response.data.error || '';

              this.$refs.num1 && this.$refs.num1.focus();
            } else if (response.data.status === 200) {
              localStorage.setItem('user', JSON.stringify(response.data.data));
              this.tryingToLogIn = false;
              this.isAuthError = false;
              this.$router.push(this.$route.query.redirectFrom || { name: 'home' });
            } else {
              this.toast(this.$t('login.loginerror'));
              this.isAuthError = true;
              this.authError = response.data.error || '';
            }
          })
          .catch((error) => {
            console.error('登录请求失败:', error);
            this.toast(this.$t('login.requestFailed'));
          });
      }
    },

    tryToLogInExtend() {
      var that = this;
      let authcodes = `${that.num11}${that.num22}${that.num33}${that.num44}${that.num55}${that.num66}`;
      if (authcodes.length !== 6) {
        return;
      }
      that.$axios
        .post(that.apiuri, {
          action: 'otplogin',
          authcodes: authcodes,
        })
        .then((response) => {
          if (response.data.status === 200) {
            localStorage.setItem('user', JSON.stringify(response.data.data));
            that.tryingToLogIn = false;
            that.isAuthError = false;
            that.$router.push(that.$route.query.redirectFrom || { name: 'home' });
          } else {
            that.toast(that.$t('login.otploginerror'));
            that.isAuthError = true;
            that.authError = response.data.error || '';
          }
        })
        .catch((error) => {
          console.error('登录请求失败:', error);
          that.toast(that.$t('login.requestFailed'));
        });
    },

    nextFocus(el, index) {
      console.log(this.inputList);
      var numReg = /^[0-9]*$/;
      var numRe = new RegExp(numReg);
      if (numRe.test(el.key) || el.keyCode === 8) {
        var dom = this.$el.getElementsByClassName('border-input'),
          currInput = dom[index],
          nextInput = dom[index + 1],
          lastInput = dom[index - 1];
        /*这里的keyCode 根据不同的平台或许不同,安卓就是不是8*/
        if (el.keyCode !== 8) {
          this.inputList[index] = el.key;

          if (index < this.inputList.length - 1) {
            nextInput.focus();
          } else {
            currInput.blur();
            this.tryToLogInExtend();
          }
        } else {
          this.inputList[index] = '';
          if (index !== 0) {
            lastInput.focus();
          }
        }
      }
    },

    // 生成二维码
    async generateQRCode(qrcodelink) {
      try {
        const canvas = this.$refs.qrcodeCanvas;
        await QRCode.toCanvas(canvas, qrcodelink, { width: 400, height: 400 });
        this.qrcodeloading = false;
      } catch (error) {
        console.error('二维码生成失败:', error);
      }
    },

    // 切换登录类型
    changeLoginType(tab, event) {
      if (tab.name === 'qrcode') {
        this.qrcodeloading = true;
        this.$axios
          .post(this.apiuri, { action: 'wechatqrcodelogin' })
          .then((response) => {
            if (response.data.status === 200) {
              const qrcodelink = response.data.data.url;
              this.generateQRCode(qrcodelink);
              this.qrcodeexpire_seconds = response.data.data.expire_seconds;
              this.qrcodecountdown = setInterval(() => {
                this.qrcodeexpire_seconds--;
                if (this.qrcodeexpire_seconds === 0) {
                  clearInterval(this.qrcodecountdown); // 停止计时器
                  this.changeLoginType({ name: 'qrcode' });
                }
              }, 1000);
            } else {
              console.error('获取二维码链接失败');
            }
          })
          .catch((error) => {
            console.error('请求失败:', error);
          });
      }
    },

    // 检查登录
    checklogin(logincode, openid) {
      this.qrcodelogin_request = true;
      this.$axios
        .post(this.apiuri, { action: 'qrlogin', logincode, openid })
        .then((response) => {
          if (response.data.status === 200) {
            localStorage.setItem('user', JSON.stringify(response.data.data));
            this.tryingToLogIn = false;
            this.isAuthError = false;
            this.$router.push(this.$route.query.redirectFrom || { name: 'home' });
            clearInterval(this.qrcodecountdown);
          } else {
            this.toast(this.$t('login.wechat_qrlogin_error'));
            this.isAuthError = true;
            this.authError = response.data.error || '';
          }
        })
        .catch((error) => {
          console.error('二维码登录失败:', error);
        });
    },

    // 获取 OTP 状态
    getotpstatus() {
      this.$axios
        .post(this.apiuri, { action: 'getotpstatus' })
        .then((response) => {
          if (response.data.status === 200) {
            this.otp_status = true;
            this.otp_enable = true;
          }
        })
        .catch((error) => {
          console.error('获取 OTP 状态失败:', error);
        });
    },

    // 显示通知
    toast(text, append = false) {
      this.counter++;
      this.$bvToast.toast(text, {
        title: this.$t('login.alter'),
        toaster: 'b-toaster-top-center',
        solid: true,
        appendToast: append,
      });
    },
    handleInput(id) {
      let value = this[`num${id}${id}`] || '';
      // 移除非数字字符
      value = value.replace(/\D/g, '');
      // 保留第一个字符
      value = value.charAt(0);
      this[`num${id}${id}`] = value;

      if (value) {
        this.$nextTick(() => {
          const nextId = Number(id) + 1;
          if (nextId <= 6) {
            const nextInput = this.$refs[`num${nextId}`];
            if (nextInput) {
              nextInput.focus();
            }
          } else {
            this.$refs.num6.blur();
            // 在最后一个输入后触发登录逻辑
            this.tryToLogInExtend();
          }
        });
      }
    },
    showOTPMicoapp() {
      this.$alert(
        `<img width="100%" src="${this.imgsrc}"><p>${this.$t("setting.system.otpbind_support_tip")}</p>`,
        this.$t("setting.system.otpbind_micoapp"),
        {
          confirmButtonText: this.$t("global.button.ok"),
          dangerouslyUseHTMLString: true,
          callback: (action) => {
            if (action === "confirm") {
              // 弹出输入框用于确认2FA验证码

            }
          },
        }
      );
    }
  },
  mounted() {
    document.body.classList.add('auth-body-bg');
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    let languagecode = navigator.language;
    if (languagecode === 'zh-HK' || languagecode === 'zh-TW' || languagecode === 'zh-MO') {
      languagecode = 'zh-HK';
    } else {
      languagecode = languagecode.substr(0, 2);
    }
    i18n.locale = languagecode;

    // 监听全局消息
    this.$bus.$on('message', (data) => {
      if (data.action === 'login' && data.status === 'success') {
        if (!this.qrcodelogin_request) this.checklogin(data.code, data.openid);
      }
    });

    this.getotpstatus();
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-md-4 col-lg-2"></div>
          <div class="col-md-4 col-lg-8">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/login-logo.webp" width="60%" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">
                          {{ $t('login.login') }}
                        </h4>
                        <p class="text-muted" v-if="extendvoid">
                          {{ $t('login.inputcode') }}
                        </p>
                      </div>

                      <b-alert variant="danger" class="mt-3" v-if="notification.message" show dismissible>
                        {{ notification.message }}
                      </b-alert>

                      <el-tabs v-model="logintype" type="border-card" @tab-click="changeLoginType">
                        <el-tab-pane :label="$t('login.type.password')" name="password">
                          <form class="form-horizontal" @submit.prevent="tryToLogIn">
                            <div class="form-group auth-form-group-custom mb-4">
                              <i class="ri-mail-line auti-custom-input-icon"></i>
                              <label for="username">
                                {{ $t('login.username.text') }}
                              </label>
                              <input type="text" v-model="email" class="form-control" id="username"
                                :placeholder="$t('login.username.placeholder')" :class="{
                                  'is-invalid': submitted && $v.email.$error,
                                }" />
                              <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                                <span v-if="!$v.email.required">
                                  {{ $t('login.username.noinput') }}
                                </span>
                              </div>
                            </div>

                            <div class="form-group auth-form-group-custom mb-4">
                              <i class="ri-lock-2-line auti-custom-input-icon"></i>
                              <label for="userpassword">
                                {{ $t('login.password.text') }}
                              </label>
                              <input v-model="password" type="password" class="form-control" id="userpassword"
                                :placeholder="$t('login.password.placeholder')" :class="{
                                  'is-invalid': submitted && $v.password.$error,
                                }" />
                              <div v-if="submitted && !$v.password.required" class="invalid-feedback">
                                {{ $t('login.password.noinput') }}
                              </div>
                            </div>

                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="customControlInline"
                                v-model="remember" />
                              <label class="custom-control-label" for="customControlInline">
                                {{ $t('login.remember') }}
                              </label>
                            </div>

                            <div class="mt-4 text-center">
                              <button class="btn btn-primary w-md waves-effect waves-light" type="submit">
                                {{ $t('login.login') }}
                              </button>
                            </div>

                            <div class="mt-4 text-center">
                              <!-- <router-link tag="a" to="/forgot-password" class="text-muted">
                                <i class="mdi mdi-lock mr-1"></i>
                                {{ $t('login.forgot') }}
                              </router-link> -->
                            </div>
                          </form>
                        </el-tab-pane>

                        <el-tab-pane :label="$t('login.type.qrcode')" name="qrcode">
                          <div class="qrcode-container">
                            <div v-if="qrcodeloading" class="loading">
                              <div class="spinner"></div>
                              <p>{{ $t('login.qrcode.loading') }}...</p>
                            </div>
                            <canvas v-show="!qrcodeloading" ref="qrcodeCanvas"></canvas>
                            <p v-show="!qrcodeloading">
                              {{ $t('login.qrcode.timeout', { time: qrcodeexpire_seconds }) }}
                            </p>
                          </div>
                        </el-tab-pane>

                        <el-tab-pane :label="$t('login.type.card')" name="card">
                          卡片登录
                        </el-tab-pane>

                        <el-tab-pane :label="$t('login.type.otp')" name="otp">
                          <form class="form-horizontal" @submit.prevent="tryToLogInExtend" v-if="otp_status">
                            <div class="input-box" v-if="ismobile">
                              <!-- 密码输入框 -->
                              <van-password-input :value="authcodes" :length="6" :mask="false" :focused="keyboard"
                                @focus="keyboard = true" />
                              <!-- 数字键盘 -->
                              <van-number-keyboard :show="keyboard" @input="onInput" @delete="onDelete"
                                @blur="keyboard = false" />
                            </div>
                            <div class="input-box" v-else>
                              <div class="sixNumber">
                                <el-input class="itemInSix center-input" ref="num1" maxlength="1" @keyup="loseBlur('1')"
                                  @input="handleInput('1')" v-model="num11" />
                                <el-input class="itemInSix center-input" @keyup="loseBlur('2')"
                                  @input="handleInput('2')" v-model="num22" ref="num2" maxlength="1" />
                                <el-input class="itemInSix center-input" @keyup="loseBlur('3')"
                                  @input="handleInput('3')" v-model="num33" ref="num3" maxlength="1" />
                                <el-input class="itemInSix center-input" @keyup="loseBlur('4')"
                                  @input="handleInput('4')" v-model="num44" ref="num4" maxlength="1" />
                                <el-input class="itemInSix center-input" @keyup="loseBlur('5')"
                                  @input="handleInput('5')" v-model="num55" ref="num5" maxlength="1" />
                                <el-input class="itemInSix center-input" @keyup="loseBlur('6')"
                                  @input="handleInput('6')" v-model="num66" ref="num6" maxlength="1" />
                                <!-- 清空 -->
                                <el-button @click="clearAllNumber">重置</el-button>
                              </div>
                              <p style="text-align: right;margin-top: 10px;">
                                <i @click="showOTPMicoapp" class="el-icon-warning" style="cursor: pointer;"></i>
                              </p>
                            </div>
                          </form>
                          <div v-else>
                            <p>{{ $t('login.otp.notenabled') }}</p>
                          </div>
                        </el-tab-pane>
                      </el-tabs>

                      <div class="mt-5 text-center">
                        <!-- <p>
                          {{ $t("login.noaccount") }}
                          <router-link
                            tag="a"
                            to="/register"
                            class="font-weight-medium text-primary"
                            >{{ $t("login.register") }}</router-link
                          >
                        </p> -->
                        <p>
                          © 2016 Shijiazhuang MOE Information Technology Co.,
                          Ltd<br />
                          © 2015 AICO Group Limited company
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.qrcode-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrcode-container p {
  text-align: center;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
}

.spinner {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.sixNumber {
  display: flex;
  align-items: center;
  width: 100%;
}

.sixNumber .itemInSix {
  margin: 0 2px;
  width: 14%;
  text-align: center;
}

/* 针对 el-input 组件内部的 input 元素应用居中对齐 */
.center-input .el-input__inner {
  text-align: center;
}

/* 可选：调整字体大小和样式以优化视觉效果 */
.center-input .el-input__inner {
  font-size: 18px;
  /* 根据需要调整 */
  font-weight: bold;
  /* 根据需要调整 */
}
</style>